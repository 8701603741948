import * as React from "react"
import shortid from  "shortid";

import SvgAnimation from "../../../../assets/Innago-animated-ui.svg"

const InnagoSection2 = (props) => {
    return (
        <section className="section-2">
            <div className="container">
                <div className="title-block">
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-20">
                            <h2 className="h1 section-title normal-spacing">{props.mainTitle}</h2>
                            <p className="section-description extra-spacing">{props.mainDescription}</p>
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <div className="image-block-1">
                <object type="image/svg+xml" data={SvgAnimation}>
                    <img src={SvgAnimation} />
                </object>
                <div className="row">
                    {props.imageslist?.map((object, i) => {
                        return (
                            <div className="col-lg-24" key={shortid.generate()}>
                                <div className="item">
                                    <img
                                        src={object.image1x?.sourceUrl}
                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                        width={object.image1x?.width}
                                        alt={object.image1x?.altText}
                                    />
                                </div>
                            </div>     
                        )
                    })}
                </div>
                </div>
            </div>
            <div className="image-block-2">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-22">
                            <div className="title-block">
                                <div className="row">
                                    <div className="col-lg-1"></div>
                                    <div className="col-lg-22">
                                        <h2 className="h1 section-title extra-spacing">{props.mainTitle2}</h2>
                                    </div>
                                    <div className="col-lg-1"></div>
                                </div>
                            </div>
                            <div className="image-block">
                                <div className="row">
                                    {props.imageslist2?.map((object, i) => {
                                        return (
                                            <>
                                            <div className="col-lg-11" key={shortid.generate()}>
                                                <div className="item">
                                                    <img
                                                        src={object.image1x?.sourceUrl}
                                                        srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                        width={object.image1x?.width}
                                                        alt={object.image1x?.altText}
                                                    />
                                                </div>
                                            </div>     
                                            <div className="col-lg-2"></div>
                                            </>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                    </div>
                </div>
            </div>
            <div className="image-block-3">
                <div className="container">
                    <div className="image-block">
                        <div className="row">
                            {props.imageslist3?.map((object, i) => {
                                return (
                                    <div className="col-lg-12" key={shortid.generate()}>
                                        <div className="item">
                                            <img
                                                src={object.image1x?.sourceUrl}
                                                srcSet={object.image2x?.sourceUrl + " 2x, " + object.image1x?.sourceUrl + " 1x"}
                                                width={object.image1x?.width}
                                                alt={object.image1x?.altText}
                                            />
                                        </div>
                                    </div>     
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section> 
    )
}

export default InnagoSection2;